import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CheckCircleOutlined, Loading3QuartersOutlined } from '@ant-design/icons';
import { Alert, Button, Modal } from 'antd';
import dayjs from 'dayjs';
import { getUserFullname } from '@utils/transformation/user';
import ReadTwoTone from '@assets/icons/ReadTwoTone.svg?react';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { DONE_BUTTON_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  LABELING_GUIDELINE_MODAL_DATE_LABEL,
  PROJECT_CONFIGURATION_LABELING_GUIDELINE,
  PROJECT_CONFIGURATION_LABELING_GUIDELINE_DESCRIPTION,
  UPDATE_STATUS_LABELS,
} from '@constants/labeling';
import {
  labelingProjectCreatorSelector,
  labelingProjectLastEditorSelector,
  updateProjectStatusSelector,
} from '@redux/selectors/labelingSelectors';
import { ILabelingProject } from '@redux/types/types';
import TextEditor from '@components/textEditor/TextEditor';
import TextEditorViewer from '@components/textEditorViewer/TextEditorViewer';
import styles from './labelingGuidelineModal.module.scss';

interface ILabelingGuidelineModalProps {
  open: boolean;
  initValue?: string | null;
  project?: ILabelingProject;
  readOnly?: boolean;
  onCancel: () => void;
  onDone: () => void;
  onChange?: (value: string) => void;
}

const LabelingGuidelineModal = ({
  open,
  initValue,
  project,
  readOnly,
  onChange,
  onCancel,
  onDone,
}: ILabelingGuidelineModalProps) => {
  const textEditorRef = useRef<React.ElementRef<typeof TextEditor>>(null);
  const updateProjectStatus = useSelector(updateProjectStatusSelector);
  const labelingProjectCreator = useSelector(labelingProjectCreatorSelector);
  const labelingProjectLastEditor = useSelector(labelingProjectLastEditorSelector);
  const [updateInfo, setUpdateInfo] = useState<{ label: string; icon: React.ReactElement } | null>(
    null,
  );

  useEffect(() => {
    if (initValue && open) textEditorRef.current?.setHTMLContent(initValue);
  }, [open]);

  useEffectUpdateOnly(() => {
    if (updateProjectStatus === StatusCodes.IN_PROGRESS) {
      setUpdateInfo({
        label: UPDATE_STATUS_LABELS.SAVING,
        icon: <Loading3QuartersOutlined className={styles.updateInfo_icon_loading} spin />,
      });
      return;
    }
    if (updateProjectStatus === StatusCodes.SUCCESS)
      setUpdateInfo({
        label: UPDATE_STATUS_LABELS.CHANGES_SAVED,
        icon: <CheckCircleOutlined className={styles.updateInfo_icon_success} />,
      });
  }, [updateProjectStatus]);

  const onModalVisibilityChange = (openState: boolean) => {
    if (!openState) {
      setUpdateInfo(null);
    }
  };

  const renderFooter = () => {
    return (
      <div className={styles.labelingGuidelineModal_footer}>
        {!readOnly && updateInfo && (
          <div className={styles.updateInfo}>
            {updateInfo.icon}
            <span className={styles.updateInfo_label}>{updateInfo.label}</span>
          </div>
        )}
        <Button type="primary" onClick={onDone}>
          {DONE_BUTTON_LABEL}
        </Button>
      </div>
    );
  };

  const renderModalHeader = () => {
    if (!project) {
      return (
        <Alert
          message={PROJECT_CONFIGURATION_LABELING_GUIDELINE_DESCRIPTION}
          banner
          type="info"
          showIcon={false}
        />
      );
    }
    const { last_edited_by: lastEditedBy, last_edited_at: lastEditedAt } = project;

    return (
      <div className={styles.guidelinesInfo}>
        <ReadTwoTone className={styles.guidelinesInfo_icon} />
        <div>
          <div className={styles.guidelinesInfo_user}>
            {lastEditedBy
              ? getUserFullname(labelingProjectLastEditor)
              : getUserFullname(labelingProjectCreator)}
          </div>
          <div className={styles.guidelinesInfo_date}>
            {LABELING_GUIDELINE_MODAL_DATE_LABEL} {dayjs(lastEditedAt).format('dddd D MMMM YYYY')}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      title={PROJECT_CONFIGURATION_LABELING_GUIDELINE}
      width="78%"
      open={open}
      className={styles.labelingGuidelineModal}
      centered
      onCancel={onCancel}
      afterOpenChange={onModalVisibilityChange}
      footer={renderFooter()}
    >
      {renderModalHeader()}
      <div className={styles.labelingGuidelineModal_body}>
        {readOnly && project ? (
          <TextEditorViewer content={project.annotation_guideline || ''} />
        ) : (
          <TextEditor
            ref={textEditorRef}
            onTextChangeByUser={() => onChange && onChange(textEditorRef.current!.getHTMLContent())}
            resizable={false}
          />
        )}
      </div>
    </Modal>
  );
};

export default LabelingGuidelineModal;
