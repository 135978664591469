import { createAsyncThunk } from '@reduxjs/toolkit';
import { normalizeErrorMessage } from '@utils/error';
import { downloadBlobFile } from '@utils/file';
import { Navigator } from '@utils/navigator';
import {
  createLabelApi,
  createLabelingProjectApi,
  deleteLabelingProjectApi,
  getLabelingProjectApi,
  getLabelingProjectsApi,
  getLabelingProjectStatsApi,
  getLabelsApi,
  getLabelsCSVApi,
  getRecursivelyLabelsApi,
  ILabelingProjectBody,
  updatedLabelingProjectApi,
  updateLabelApi,
} from '@api/labeling';
import { getUserApi } from '@api/users';
import { MIMETypes } from '@constants/enum/common';
import { LABEL_ADDED_MESSAGE, LABEL_UPDATED_MESSAGE } from '@constants/labeling';
import {
  CREATE_DOCUMENT_RETRIEVAL_LABEL,
  CREATE_LABELING_PROJECT,
  DELETE_LABELING_PROJECT,
  EXPORT_LABELS,
  GET_LABELING_PROJECT,
  GET_LABELING_PROJECT_CREATOR,
  GET_LABELING_PROJECT_LABELS,
  GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS,
  GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT,
  GET_LABELING_PROJECT_LAST_EDITOR,
  GET_LABELING_PROJECT_STATS,
  GET_LABELING_PROJECTS,
  ILabelingProjectLabel,
  ISearchResultDocument,
  LabelRelevance,
  NotificationDuration,
  NotificationType,
  RESET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT,
  UPDATE_DOCUMENT_RETRIEVAL_LABEL,
  UPDATE_LABELING_PROJECT,
} from '@redux/types/types';
import { addNotification } from './notificationActions';

export const resetLabelsAsSearchResult = {
  type: RESET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT,
};

export const getLabelingProjectCreator = createAsyncThunk(
  GET_LABELING_PROJECT_CREATOR,
  async (userId: string, { rejectWithValue }) => {
    try {
      const { data } = await getUserApi(userId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLabelingProjectLastEditor = createAsyncThunk(
  GET_LABELING_PROJECT_LAST_EDITOR,
  async (userId: string, { rejectWithValue }) => {
    try {
      const { data } = await getUserApi(userId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLabelingProjects = createAsyncThunk(
  GET_LABELING_PROJECTS,
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getLabelingProjectsApi();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createLabelingProject = createAsyncThunk(
  CREATE_LABELING_PROJECT,
  async (projectParams: { name: string; description?: string }, { rejectWithValue }) => {
    try {
      const { data } = await createLabelingProjectApi(projectParams);
      Navigator.navigate(`/labeling/${data.project_id}`);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLabelingProject = createAsyncThunk(
  GET_LABELING_PROJECT,
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await getLabelingProjectApi(projectId);
      dispatch(getLabelingProjectCreator(data.created_by));
      if (data.last_edited_by) dispatch(getLabelingProjectLastEditor(data.last_edited_by));
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLabelingProjectStats = createAsyncThunk(
  GET_LABELING_PROJECT_STATS,
  async (projectId: string, { rejectWithValue }) => {
    try {
      const { data } = await getLabelingProjectStatsApi(projectId);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const exportLabels = createAsyncThunk(
  EXPORT_LABELS,
  async (
    { projectId, projectName }: { projectId: string; projectName: string },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await getLabelsCSVApi(projectId);
      downloadBlobFile(`${projectName}.csv`, response.data, MIMETypes.CSV);
      return response.data;
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          type: NotificationType.Error,
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteLabelingProject = createAsyncThunk(
  DELETE_LABELING_PROJECT,
  async (projectId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await deleteLabelingProjectApi(projectId);
      dispatch(
        addNotification({
          content: 'Labeling project deleted successfully.',
        }),
      );
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const updateLabelingProject = createAsyncThunk(
  UPDATE_LABELING_PROJECT,
  async (
    { projectId, projectBody }: { projectId: string; projectBody: ILabelingProjectBody },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await updatedLabelingProjectApi(projectId, projectBody);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const createDocumentRetrievalLabel = createAsyncThunk(
  CREATE_DOCUMENT_RETRIEVAL_LABEL,
  async (
    {
      relevance,
      projectId,
      query,
      queryId,
      result,
      rank,
    }: {
      relevance?: LabelRelevance;
      projectId: string;
      query: string;
      queryId: string;
      result: ISearchResultDocument;
      rank: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { result_id: resultId, score } = result;
    const labelBody = {
      relevance,
      search_result_history_id: resultId,
      rank,
      score: score || 0,
      document: result,
      query_id: queryId,
      query,
    };

    try {
      const {
        data: { label_id: labelId },
      } = await createLabelApi(projectId, labelBody);

      if (relevance)
        dispatch(
          addNotification({
            content: LABEL_ADDED_MESSAGE,
            type: NotificationType.Success,
          }),
        );

      return { resultId, relevance, labelId };
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          duration: NotificationDuration.long,
          type: NotificationType.Error,
        }),
      );

      return rejectWithValue(error);
    }
  },
);

export const updateDocumentRetrievalLabel = createAsyncThunk(
  UPDATE_DOCUMENT_RETRIEVAL_LABEL,
  async (
    {
      projectId,
      resultId,
      labelId,
      relevance,
    }: {
      projectId: string;
      resultId: string;
      labelId: string;
      relevance: LabelRelevance;
    },
    { rejectWithValue, dispatch },
  ) => {
    const labelBody = {
      relevance,
    };
    try {
      await updateLabelApi(projectId, labelId, labelBody);

      dispatch(
        addNotification({
          content: LABEL_UPDATED_MESSAGE,
          type: NotificationType.Success,
        }),
      );

      return { resultId, relevance, labelId };
    } catch (error) {
      dispatch(
        addNotification({
          content: normalizeErrorMessage(error),
          duration: NotificationDuration.long,
          type: NotificationType.Error,
        }),
      );

      return rejectWithValue(error);
    }
  },
);

export const getLabelingProjectLabels = createAsyncThunk(
  GET_LABELING_PROJECT_LABELS,
  async (
    params: {
      projectId: string;
      pageNumber: number;
      limit?: number;
      filter?: string;
      fetchMore?: boolean;
      includeUnlabeled?: boolean;
    },
    { rejectWithValue },
  ) => {
    const { projectId, ...restOfParams } = params;
    try {
      const { data } = await getLabelsApi(projectId, restOfParams);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLabelingProjectLabelsAsLabeledResults = createAsyncThunk(
  GET_LABELING_PROJECT_LABELS_AS_LABELED_RESULTS,
  async (
    { projectId, filter, limit }: { projectId: string; filter?: string; limit?: number },
    { rejectWithValue },
  ) => {
    try {
      const data = await getRecursivelyLabelsApi<ILabelingProjectLabel[]>(projectId, {
        filter,
        limit,
        includeUnlabeled: true,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const getLabelingProjectLabelsAsSearchResult = createAsyncThunk(
  GET_LABELING_PROJECT_LABELS_AS_SEARCH_RESULT,
  async (
    { projectId, filter, limit }: { projectId: string; filter?: string; limit?: number },
    { rejectWithValue },
  ) => {
    const params = {
      filter,
      limit,
      includeUnlabeled: true,
    };
    try {
      const data = await getRecursivelyLabelsApi<ILabelingProjectLabel[]>(projectId, params);
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
