import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { MoreOutlined } from '@ant-design/icons';
import { Alert, Button, Dropdown, Modal, Tabs, Tag } from 'antd';
import dayjs from 'dayjs';
import { debounce, isEqual } from 'lodash';
import { interpolateString } from '@utils/string';
import { getUserFullname } from '@utils/transformation/user';
import { ILabelingProjectBody } from '@api/labeling';
import { useCurrentRoute } from '@hooks/useCurrentRoute';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { StatusCodes } from '@constants/enum/common';
import {
  DELETE_PROJECT_MODAL,
  LABELING_TABS,
  LABELS_LABEL,
  MANDATORY_ALERT_DESCRIPTION,
  MANDATORY_ALERT_MESSAGE,
  PROJECT_MORE_OPTIONS_MENU_ITEMS,
  ProjectMoreOptionMenuAction,
  START_LABELING_BUTTON,
} from '@constants/labeling';
import { PipelineStatusCodes } from '@constants/pipelines';
import { getWorkspaceFiles } from '@redux/actions/fileActions';
import {
  createLabelingProject,
  deleteLabelingProject,
  exportLabels,
  getLabelingProject,
  updateLabelingProject,
} from '@redux/actions/labelingActions';
import {
  fetchPipelines,
  resetNewPipelineName,
  startPollingPipelineIndexing,
  startPollingPipelineStatus,
  stopPollingPipelineIndexing,
  stopPollingPipelineStatus,
} from '@redux/actions/pipelineActions';
import { filesSelector } from '@redux/selectors/fileSelectors';
import {
  labelingProjectCreatorSelector,
  labelingProjectSelector,
  updateProjectStatusSelector,
} from '@redux/selectors/labelingSelectors';
import {
  pipelineCreateUpdatePipelineStatusSelector,
  pipelineSelector,
  pipelinesSelector,
} from '@redux/selectors/pipelineSelectors';
import {
  EditableLabelingProjectFields,
  IAPIPaginationData,
  ILabelingProject,
  IPipeline,
  LabelingDetailsTabOptions,
} from '@redux/types/types';
import CreateUpdateLabelingProjectModal from '@components/createUpdateLabelingProjectModal/CreateUpdateLabelingProjectModal';
import DetailsHeader from '@components/detailsHeader/detailsHeader';
import LabelingOverview from './components/labelingOverview';
import LabelingProjectSettings from './components/LabelingProjectSettings';
import styles from './labelingDetailsPage.module.scss';

const PROJECT_CONFIGURATION_UPDATE_DEBOUNCE_DELAY = 750;
const { confirm } = Modal;

// TODO: Reduce unnecessary GET requests
const LabelingDetailsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { routeParams, setRouteName } = useCurrentRoute();
  const { projectId } = routeParams;
  const project: ILabelingProject = useSelector(labelingProjectSelector);
  const labelingProjectCreator = useSelector(labelingProjectCreatorSelector);
  const updateProjectStatus = useSelector(updateProjectStatusSelector);
  const { total: totalFiles }: { total: number } = useSelector(filesSelector);
  const { data: pipelines }: IAPIPaginationData<IPipeline[]> = useSelector((state) =>
    pipelinesSelector(state, null),
  );
  const pipelineForUpdates: IPipeline = useSelector(pipelineSelector);
  const [selectedPipeline, setSelectedPipeline] = useState<IPipeline | null>(null);
  const pipelineCreateUpdatePipelineStatus: StatusCodes = useSelector(
    pipelineCreateUpdatePipelineStatusSelector,
  );

  const [createProjectModalVisible, setCreateProjectModalVisible] = useState(false);
  const [mandatoryStepsCompleted, setMandatoryStepsCompleted] = useState(false);
  const [projectConfigurationFormValues, setProjectConfigurationFormValues] =
    useState<EditableLabelingProjectFields>({
      name: '',
      pipelineId: null,
      labelingGuideline: null,
      queryTarget: null,
    });
  const [activeTab, setActiveTab] = useState(LABELING_TABS[0].key);

  const getFiles = () => {
    dispatch(getWorkspaceFiles({ currentPage: 1, pageSize: 1, searchValue: '' }));
  };

  const getPipelines = () => {
    // TODO: Fetch only document retrieval pipelines
    dispatch(
      fetchPipelines({
        currentPage: 1,
        pageSize: 100,
        searchValue: '',
      }),
    );
  };

  useEffect(() => {
    if (!projectId) return;
    dispatch(getLabelingProject(projectId));
    getFiles();
  }, [projectId, activeTab]);

  useEffect(() => {
    getPipelines();
    return () => {
      dispatch(resetNewPipelineName);
    };
  }, []);

  useEffect(() => {
    setProjectConfigurationFormValues({
      name: project.name,
      description: project.description,
      pipelineId: project.pipeline_id,
      labelingGuideline: project.annotation_guideline,
      queryTarget: project.query_target,
    });
    if (project.name) setRouteName(project.name);
  }, [project]);

  // Pipelines
  // On new pipelines created
  useEffect(() => {
    if (pipelineCreateUpdatePipelineStatus === StatusCodes.SUCCESS) getPipelines();
  }, [pipelineCreateUpdatePipelineStatus]);

  useEffect(() => {
    if (projectConfigurationFormValues.pipelineId === pipelineForUpdates.pipeline_id) {
      setSelectedPipeline(pipelineForUpdates);
      return;
    }

    setSelectedPipeline(
      pipelines.find(
        ({ pipeline_id }) => pipeline_id === projectConfigurationFormValues.pipelineId,
      ) ?? null,
    );
  }, [projectConfigurationFormValues.pipelineId, pipelines]);

  useEffectUpdateOnly(() => {
    // For pipeline updates (e.g. deployement status updates)
    if (selectedPipeline?.name === pipelineForUpdates.name) setSelectedPipeline(pipelineForUpdates);
  }, [pipelineForUpdates]);

  useEffect(() => {
    if (!selectedPipeline) return () => {};

    const { status, name, indexing } = selectedPipeline;
    if (
      status !== PipelineStatusCodes.DEPLOYED ||
      activeTab === LabelingDetailsTabOptions.SETTINGS
    ) {
      dispatch(startPollingPipelineStatus({ pipelineName: name }));
      dispatch(startPollingPipelineIndexing(name));
    }

    if (activeTab !== LabelingDetailsTabOptions.SETTINGS) {
      dispatch(stopPollingPipelineStatus());
      dispatch(stopPollingPipelineIndexing());
    }

    const {
      pending_file_count: pipelinePendingFileCount,
      failed_file_count: pipelineFailedFileCount,
    } = indexing;

    if (pipelinePendingFileCount === 0 && pipelineFailedFileCount === 0) {
      dispatch(stopPollingPipelineIndexing());
    }

    return () => {
      dispatch(stopPollingPipelineStatus());
      dispatch(stopPollingPipelineIndexing());
    };
  }, [selectedPipeline, activeTab]);

  // Mandatory steps check

  useEffect(() => {
    if (totalFiles > 0 && selectedPipeline) {
      if (selectedPipeline?.status === PipelineStatusCodes.DEPLOYED) {
        setMandatoryStepsCompleted(true);
        return;
      }
    }

    setMandatoryStepsCompleted(false);
  }, [totalFiles, selectedPipeline]);

  const getUpdateProjectConfigurationBody = () => {
    return {
      name: projectConfigurationFormValues.name,
      description: projectConfigurationFormValues.description,
      pipeline_id: projectConfigurationFormValues.pipelineId,
      query_target: projectConfigurationFormValues.queryTarget,
      annotation_guideline: projectConfigurationFormValues.labelingGuideline,
    };
  };

  const handleUpdatingProjectConfiguration = (
    projectBody: ILabelingProjectBody = getUpdateProjectConfigurationBody(),
  ) => {
    if (projectId) dispatch(updateLabelingProject({ projectId, projectBody }));
  };

  const handleUpdatingProjectConfigurationLocalState = (
    field: string,
    value: string | number | null,
  ) => {
    setProjectConfigurationFormValues((prevValue) => ({
      ...prevValue,
      [field]: value,
    }));
  };

  const debouncedHandleUpdatingProjectConfiguration = useMemo(
    () =>
      debounce(
        (projectBody) => handleUpdatingProjectConfiguration(projectBody),
        PROJECT_CONFIGURATION_UPDATE_DEBOUNCE_DELAY,
      ),
    [projectId],
  );

  useEffectUpdateOnly(() => {
    const {
      name,
      description,
      pipeline_id: pipelineId,
      query_target: queryTarget,
      annotation_guideline: labelingGuideline,
    } = project;
    if (
      !isEqual(
        { queryTarget, labelingGuideline, name, description, pipelineId },
        projectConfigurationFormValues,
      )
    )
      debouncedHandleUpdatingProjectConfiguration(getUpdateProjectConfigurationBody());
  }, [projectConfigurationFormValues]);

  const handleProjectMoreOptionsMenuClick = (key: string) => {
    const { name, project_id: id } = project;
    switch (key) {
      case ProjectMoreOptionMenuAction.Edit:
        setCreateProjectModalVisible(true);
        break;
      case ProjectMoreOptionMenuAction.Export:
        dispatch(exportLabels({ projectName: name, projectId: id }));
        break;
      case ProjectMoreOptionMenuAction.Duplicate: {
        const temp = { ...project, name: `(Copy) ${name}` };
        dispatch(createLabelingProject(temp));
        navigate('/labeling');
        break;
      }
      case ProjectMoreOptionMenuAction.Delete:
        confirm({
          className: styles.deleteConfirmModal,
          title: DELETE_PROJECT_MODAL.title,
          // eslint-disable-next-line react/jsx-pascal-case
          icon: <DELETE_PROJECT_MODAL.icon />,
          content: interpolateString(DELETE_PROJECT_MODAL.description, {
            projectName: <strong>{name}</strong>,
          }),
          onOk: () => {
            dispatch(deleteLabelingProject(id));
            navigate('/labeling');
          },
          okType: DELETE_PROJECT_MODAL.okType,
          okText: DELETE_PROJECT_MODAL.okText,
        });
        break;
      default:
        break;
    }
  };

  const handleSubmitingUpdateProjectModal = ({
    name,
    description,
  }: {
    name: string;
    description?: string;
  }) => {
    setProjectConfigurationFormValues((prev) => ({
      ...prev,
      name,
      description: description || '',
    }));
    setCreateProjectModalVisible(false);
  };

  const handleClosingCreateUpdateProjectModal = () => {
    setCreateProjectModalVisible(false);
  };

  // Renders
  const renderActions = () => (
    <div className={styles.titleSection_actions}>
      <Button
        type="primary"
        disabled={!mandatoryStepsCompleted}
        onClick={() => navigate(`query/${selectedPipeline?.name}`)}
      >
        {START_LABELING_BUTTON}
      </Button>
      <Dropdown
        menu={{
          items: PROJECT_MORE_OPTIONS_MENU_ITEMS,
          onClick: ({ key }) => handleProjectMoreOptionsMenuClick(key),
        }}
        placement="bottomRight"
        trigger={['click']}
      >
        <Button icon={<MoreOutlined />} onClick={(e) => e.preventDefault()} />
      </Dropdown>
    </div>
  );

  const renderDescription = () => {
    return <div className={styles.description}>{projectConfigurationFormValues.description}</div>;
  };

  const renderAlert = () => {
    if (!mandatoryStepsCompleted)
      return (
        <Alert
          message={MANDATORY_ALERT_MESSAGE}
          description={MANDATORY_ALERT_DESCRIPTION}
          type="warning"
          className={styles.alert}
        />
      );
    return null;
  };

  return (
    <div className={styles.labelingDetailsPage}>
      <CreateUpdateLabelingProjectModal
        initialValues={{
          name: projectConfigurationFormValues.name,
          description: projectConfigurationFormValues.description,
          id: project.project_id,
        }}
        loading={updateProjectStatus === StatusCodes.IN_PROGRESS}
        open={createProjectModalVisible}
        onCancel={handleClosingCreateUpdateProjectModal}
        onSubmit={handleSubmitingUpdateProjectModal}
      />
      <div className={styles.labelingDetailsPage_container}>
        <DetailsHeader
          title={projectConfigurationFormValues.name}
          subtitle={dayjs(project.created_at).format('DD/MM/YYYY HH:mm')}
          createdBy={getUserFullname(labelingProjectCreator)}
          actions={renderActions()}
          status={
            <Tag>
              {project.total_labels} {LABELS_LABEL}
            </Tag>
          }
        />
        {renderDescription()}
        {renderAlert()}
        <Tabs
          activeKey={activeTab}
          items={LABELING_TABS}
          onChange={(activeKey: string) => {
            setActiveTab(activeKey as LabelingDetailsTabOptions);
          }}
        />
        <div className={styles.labelingDetailsPage_bodySection}>
          {activeTab === LabelingDetailsTabOptions.OVERVIEW && (
            <LabelingOverview
              project={project}
              totalFiles={totalFiles}
              selectedPipeline={selectedPipeline}
              onChangeTab={setActiveTab}
            />
          )}
          {activeTab === LabelingDetailsTabOptions.SETTINGS && (
            <LabelingProjectSettings
              projectConfigurationFormValues={projectConfigurationFormValues}
              totalFiles={totalFiles}
              selectedPipeline={selectedPipeline}
              onSelectPipeline={setSelectedPipeline}
              onProjectConfigurationLocalStateChange={handleUpdatingProjectConfigurationLocalState}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default LabelingDetailsPage;
