import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircleFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import { Alert, Button, Divider } from 'antd';
import { isPipelineDeployed } from '@utils/pipelines';
import { interpolateString } from '@utils/string';
import { OPTIONAL_LABEL } from '@constants/common';
import {
  ADD_BUTTON_LABEL,
  EDIT_BUTTON_LABEL,
  MANDATORY_LABEL,
  OVERVIEW_MENU_ITEMS,
  PIPELINE_NOT_DEPLOYED_ALERT,
  PROJECT_CONFIGURATION_INDEXING_PIPELINE_ALERT,
  SETTINGS_LINK_LABEL,
  UPLOAD_BUTTON_LABEL,
} from '@constants/labeling';
import {
  ILabelingProject,
  IPipeline,
  LabelingDetailsTabOptions,
  NotificationType,
} from '@redux/types/types';
import styles from './labelingOverview.module.scss';

interface ILabelingOverviewProps {
  project: ILabelingProject;
  totalFiles: number;
  selectedPipeline: IPipeline | null;
  onChangeTab: (tab: LabelingDetailsTabOptions) => void;
}

const LabelingOverview = (props: ILabelingOverviewProps) => {
  const navigate = useNavigate();
  const { project, totalFiles, selectedPipeline, onChangeTab } = props;

  const handleOnEdit = () => {
    onChangeTab(LabelingDetailsTabOptions.SETTINGS);
  };

  // Pipeline methods

  const getOverviewPipelineStatus = () => {
    if (!selectedPipeline) return NotificationType.Warning;
    return isPipelineDeployed(selectedPipeline.status)
      ? NotificationType.Success
      : NotificationType.Warning;
  };

  const getOverviewPipelineAlert = () => {
    if (!selectedPipeline) return null;
    if (selectedPipeline?.indexing?.pending_file_count > 0)
      return {
        message: PROJECT_CONFIGURATION_INDEXING_PIPELINE_ALERT,
      };
    if (isPipelineDeployed(selectedPipeline.status)) return null;
    return {
      message: interpolateString(PIPELINE_NOT_DEPLOYED_ALERT, {
        settings: (
          <Button type="link" onClick={handleOnEdit}>
            {SETTINGS_LINK_LABEL}
          </Button>
        ),
      }),
    };
  };

  const getOverviewPipelineCTA = () => {
    if (!selectedPipeline) return null;
    if (isPipelineDeployed(selectedPipeline.status)) return null;
    return {
      type: 'link',
      label: EDIT_BUTTON_LABEL,
    };
  };

  const renderListItem = ({
    label,
    mandatory,
    status,
    cta,
    alert,
  }: {
    label: string;
    mandatory: Boolean;
    status?: NotificationType;
    cta?: {
      label?: string;
      type?: string;
      disabled?: boolean;
      function?: () => void;
    } | null;
    alert?: {
      message: string | React.ReactElement;
    } | null;
  }) => (
    <li>
      {!status && <ExclamationCircleOutlined />}
      {status === NotificationType.Success && <CheckCircleFilled className={styles.success} />}
      {status === NotificationType.Warning && (
        <ExclamationCircleOutlined className={styles.warning} />
      )}
      <span className={styles.labelWrapper}>
        <div className={styles.info}>{mandatory ? MANDATORY_LABEL : OPTIONAL_LABEL}</div>
        <div className={styles.label}>{label}</div>
        {!!alert && (
          <Alert className={styles.alert} showIcon={false} type="warning" message={alert.message} />
        )}
      </span>
      {!cta?.disabled && (
        <Button
          type={cta?.type || status === NotificationType.Success ? 'link' : 'default'}
          onClick={cta?.function || handleOnEdit}
        >
          {cta?.label ||
            (status === NotificationType.Success ? EDIT_BUTTON_LABEL : ADD_BUTTON_LABEL)}
        </Button>
      )}
    </li>
  );

  return (
    <div>
      <ul className={styles.list}>
        {OVERVIEW_MENU_ITEMS.map((item, index) => {
          let status;
          let cta;
          let alert;

          if (project[item.key as keyof typeof project]) status = NotificationType.Success;
          if (item.mandatory && !project[item.key as keyof typeof project])
            status = NotificationType.Warning;

          if (item.key === 'files') {
            const thereAreUploadedFiles = totalFiles > 0;
            status = thereAreUploadedFiles ? NotificationType.Success : NotificationType.Warning;
            cta = {
              label: UPLOAD_BUTTON_LABEL,
              disabled: thereAreUploadedFiles,
              function: () => {
                navigate('/files');
              },
            };
          }

          if (item.key === 'pipeline_id') {
            status = getOverviewPipelineStatus();
            alert = getOverviewPipelineAlert();
            cta = getOverviewPipelineCTA();
          }

          return (
            <div key={item.key}>
              {renderListItem({ label: item.label, mandatory: item.mandatory, status, cta, alert })}
              {index !== OVERVIEW_MENU_ITEMS.length - 1 && <Divider />}
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default LabelingOverview;
