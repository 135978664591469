import { createSelector } from 'reselect';
import { RootState } from '@redux/store';

export const labelingStoreSelector = (state: RootState) => state.labelingStore;

export const labelingProjectsSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.projects,
);

export const createLabelingProjectStatusSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.createProjectStatus,
);

export const labelingProjectSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.project,
);

export const labelingProjectStatsSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.projectStats,
);

export const labelingProjectCreatorSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.projectCreator,
);

export const labelingProjectLastEditorSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.projectLastEditor,
);

export const updateProjectStatusSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.updateProjectStatus,
);

export const labeledResultsSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.labeledResults,
);

export const labelingProjectLabelsSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.labels,
);

export const labelingProjectLabelsStatusSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.labelsStatus,
);

export const labelingProjectLabelsAsSearchResultSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.labelsAsSearchResult,
);

export const labelingProjectLabelsAsSearchResultStatusSelector = createSelector(
  labelingStoreSelector,
  (labelingStore) => labelingStore.labelsAsSearchResultStatus,
);
