import {
  BuildOutlined,
  ExperimentOutlined,
  FileDoneOutlined,
  FileTextOutlined,
  FundOutlined,
  HomeOutlined,
  LayoutOutlined,
  MessageOutlined,
  PlayCircleOutlined,
  ProjectOutlined,
  TagsOutlined,
} from '@ant-design/icons';
import { UserRoles } from './enum/common';

export interface ISidebarMenuItem {
  label: string;
  link?: string;
  icon?: any;
  userAccess?: UserRoles[];
  submenu?: ISidebarMenuItem[];
  newLabel?: boolean;
  betaLabel?: boolean;
}

export type ISidebarMenuSettings = ISidebarMenuItem[];

export const SIDEBAR_MENU_ITEMS: ISidebarMenuSettings = [
  {
    label: 'Home',
    link: '/',
    icon: HomeOutlined,
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Data',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Files',
    link: '/files',
    icon: FileTextOutlined,
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Labeling',
    link: '/labeling',
    icon: TagsOutlined,
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Evaluation Sets',
    link: '/evalset',
    icon: FileDoneOutlined,
    userAccess: [UserRoles.ADMIN],
  },

  {
    label: 'Configuration',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Pipeline Templates',
    link: '/pipelines/templates',
    icon: LayoutOutlined,
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Pipelines',
    link: '/pipelines',
    icon: ProjectOutlined,
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Jobs',
    link: '/jobs',
    icon: BuildOutlined,
    userAccess: [UserRoles.ADMIN],
  },

  {
    label: 'Testing',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Playground',
    link: '/playground',
    icon: PlayCircleOutlined,
    userAccess: [UserRoles.ADMIN, UserRoles.SEARCH_USER],
  },
  {
    label: 'Prompt Studio',
    link: '/prompt-studio',
    icon: MessageOutlined,
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Experiments',
    link: '/experiments',
    icon: ExperimentOutlined,
    userAccess: [UserRoles.ADMIN],
  },

  {
    label: 'Monitoring',
    userAccess: [UserRoles.ADMIN],
  },
  {
    label: 'Groundedness',
    link: '/groundedness',
    icon: FundOutlined,
    userAccess: [UserRoles.ADMIN],
  },
];

export const WORKSPACE_LABEL = 'Workspace';
export const WORKSPACE_INPUT_PLACEHOLDER = 'Workspace name';
export const WORKSPACE_CREATE_BUTTON_LABEL = 'Create';
