import React from 'react';
import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import LeaderboardIcon from '@assets/icons/Leaderboard.svg?react';
import LeaderboardTwoToneIcon from '@assets/icons/LeaderboardTwoTone.svg?react';
import {
  LABELING_LEADERBOARD_EMPTY_MESSAGE,
  LABELING_LEADERBOARD_POSITION_LABEL,
  LABELING_PROGRESS_QUERIES_LABEL,
} from '@constants/labeling';
import { ILabelingLeaderboardUser } from '@redux/types/types';
import styles from './labelingLeaderboard.module.scss';

interface ILabelingLeaderboardProps {
  queriesPerUser: ILabelingLeaderboardUser[];
}

const LabelingLeaderboard = ({ queriesPerUser }: ILabelingLeaderboardProps) => {
  return (
    <div>
      <div className={styles.header}>
        <span> {LABELING_LEADERBOARD_POSITION_LABEL} </span>
        <span> {LABELING_PROGRESS_QUERIES_LABEL} </span>
      </div>
      {queriesPerUser && queriesPerUser.length > 0 ? (
        <ul className={styles.list}>
          {queriesPerUser.map(({ nickname, amount }, index) => (
            <li key={nickname}>
              <span className={styles.listItem_user}>
                <span className={styles.listItem_user_order}>{index + 1}.</span>
                {index === 0 ? (
                  <LeaderboardTwoToneIcon className={styles.listItem_user_icon} />
                ) : (
                  <Avatar size={24} className={styles.listItem_user_icon} icon={<UserOutlined />} />
                )}
                {nickname}
              </span>
              <span className={styles.listItem_score}>{amount}</span>
            </li>
          ))}
        </ul>
      ) : (
        <div className={styles.emptyContainer}>
          <LeaderboardIcon className={styles.emptyContainer_icon} />
          {LABELING_LEADERBOARD_EMPTY_MESSAGE}
        </div>
      )}
    </div>
  );
};

export default LabelingLeaderboard;
