import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'antd';
import dayjs from 'dayjs';
import { groupBy, uniqBy } from 'lodash';
import { getODataEQFilterFrom } from '@utils/odata';
import { DONE_BUTTON_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import { LABELING_QUERY_HISTORY_TITLE } from '@constants/labeling';
import { getLabelingProjectLabels } from '@redux/actions/labelingActions';
import {
  labelingProjectLabelsSelector,
  labelingProjectLabelsStatusSelector,
} from '@redux/selectors/labelingSelectors';
import { userSelector } from '@redux/selectors/userSelectors';
import { ILabelingProjectLabel } from '@redux/types/types';
import QueryHistoryList, { IListItem } from '@components/queryHistoryList/QueryHistoryList';
import styles from './labelingQueryHistory.module.scss';

const FETCH_LABELS_LIMIT = 100;
const QUERY_LIST_CONTAINER_ID = 'queryListContainer';

interface ILabelingQueryHistoryProps {
  open: boolean;
  projectId: string;
  currentQueryId: string | null;
  onCancel: () => void;
  onDone: () => void;
  onQuerySelect: (queryId: string) => void;
}

const LabelingQueryHistory = ({
  open,
  projectId,
  currentQueryId,
  onCancel,
  onDone,
  onQuerySelect,
}: ILabelingQueryHistoryProps) => {
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const labelingProjectLabels = useSelector(labelingProjectLabelsSelector);
  const labelingProjectLabelsStatus = useSelector(labelingProjectLabelsStatusSelector);
  const [labelingProjectLabelsPage, setLabelingProjectLabelsPage] = useState(1);
  const [parsedLabelsToHistory, setParsedLabelsToHistory] = useState<Record<string, IListItem[]>>(
    {},
  );

  const getUserLabels = (extraParams?: { pageNumber?: number; fetchMore?: boolean }) => {
    const filter = getODataEQFilterFrom('created_by', user.userID);
    dispatch(
      getLabelingProjectLabels({
        projectId,
        filter,
        pageNumber: labelingProjectLabelsPage,
        limit: FETCH_LABELS_LIMIT,
        includeUnlabeled: true,
        ...extraParams,
      }),
    );
  };

  const loadMoreUserLabels = () => {
    const nextPage = labelingProjectLabelsPage + 1;
    setLabelingProjectLabelsPage(nextPage);
    getUserLabels({ pageNumber: nextPage, fetchMore: true });
  };

  const parseLabelsToHistoryList = (labels: ILabelingProjectLabel[]) => {
    const parsedToListItem = labels.map(({ query, query_id: id, created_at: createdAt }) => ({
      query,
      id,
      createdAt,
    }));
    const uniqueQueries = uniqBy(parsedToListItem, 'id');
    const groupedByDate = groupBy(uniqueQueries, ({ createdAt }) =>
      dayjs(createdAt).format('dddd D MMMM YYYY'),
    );

    return groupedByDate;
  };

  useEffect(() => {
    if (!open) {
      setLabelingProjectLabelsPage(1);
      return;
    }

    getUserLabels();
  }, [open]);

  useEffect(() => {
    const { data, total } = labelingProjectLabels;
    if (total < 1) return;

    setParsedLabelsToHistory(parseLabelsToHistoryList(data));
  }, [labelingProjectLabels]);

  const handleSelectingQueryItem = (listItem: IListItem) => {
    onQuerySelect(listItem.id);
    onCancel();
  };

  return (
    <Modal
      title={LABELING_QUERY_HISTORY_TITLE}
      width="78%"
      open={open}
      className={styles.labelingQueryHistory}
      centered
      onCancel={onCancel}
      cancelText={false}
      cancelButtonProps={{ style: { display: 'none' } }}
      onOk={onDone}
      okText={DONE_BUTTON_LABEL}
    >
      <div className={styles.labelingQueryHistory_body} id={QUERY_LIST_CONTAINER_ID}>
        <div className={styles.zeroHeightContainer}>
          <QueryHistoryList
            isLoading={labelingProjectLabelsStatus === StatusCodes.IN_PROGRESS}
            total={labelingProjectLabels.total}
            hasMore={labelingProjectLabels.has_more}
            onNext={loadMoreUserLabels}
            scrollableTarget={QUERY_LIST_CONTAINER_ID}
            dataGroupedByDate={parsedLabelsToHistory}
            isSelected={(item) => item.id === currentQueryId}
            onItemSelect={handleSelectingQueryItem}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LabelingQueryHistory;
