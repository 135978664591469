import { getCurrentWorkspaceId } from '@utils/store';
import { MIMETypes } from '@constants/enum/common';
import { IAPIPaginationData, LabelRelevance } from '@redux/types/types';
import { http } from './base-http';

// TODO: Create a decorator for specifying api version

export interface ILabelingProjectBody {
  name: string;
  description?: string;
  pipeline_id?: string | null;
  query_target?: number | null;
  annotation_guideline?: string | null;
}

export interface IAnnotationLabelBody {
  relevance?: LabelRelevance;
  comment?: string;
  search_result_history_id: string;
  rank: number;
  score: number;
  document: unknown;
  query_id: string;
  query: string;
}

export interface ILabelsAPIParams {
  filter?: string;
  pageNumber?: number;
  limit?: number;
  includeUnlabeled?: boolean;
}

export const getLabelingProjectsApi = (workspaceId: string = getCurrentWorkspaceId()) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/labeling_projects`);
};

export const createLabelingProjectApi = (
  projectBody: ILabelingProjectBody,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.post(`/api/v2/workspaces/${workspaceId}/labeling_projects`, projectBody);
};

export const getLabelingProjectApi = (
  projectId: string,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}`);
};

export const getLabelingProjectStatsApi = (
  projectId: string,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}/statistics`);
};

export const updatedLabelingProjectApi = (
  projectId: string,
  projectBody: ILabelingProjectBody,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.put(`/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}`, projectBody);
};

export const deleteLabelingProjectApi = (
  projectId: string,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.delete(`/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}`);
};

export const createLabelApi = (
  projectId: string,
  labelBody: IAnnotationLabelBody,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.post(
    `/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}/labels`,
    labelBody,
  );
};

export const updateLabelApi = (
  projectId: string,
  labelId: string,
  labelBody: Pick<IAnnotationLabelBody, 'relevance' | 'comment'>,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.patch(
    `/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}/labels/${labelId}`,
    labelBody,
  );
};

export const getLabelsApi = <T>(
  projectId: string,
  params: ILabelsAPIParams,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  const mappedParams = {
    ...(params?.filter && { filter: params.filter }),
    ...(params?.limit && { limit: params.limit }),
    ...(params?.pageNumber && { page_number: params.pageNumber }),
    ...(params?.includeUnlabeled && { include_unlabeled: params.includeUnlabeled }),
  };
  return http.get<T>(`/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}/labels`, {
    params: mappedParams,
  });
};

export const getRecursivelyLabelsApi = async <T extends unknown[]>(
  projectId: string,
  params: ILabelsAPIParams,
  workspaceId: string = getCurrentWorkspaceId(),
): Promise<T | unknown[]> => {
  const {
    data: { data, has_more: hasMore },
  } = await getLabelsApi<IAPIPaginationData<T>>(projectId, params, workspaceId);

  if (!hasMore) return data;

  const nextPage = params?.pageNumber ? params.pageNumber + 1 : 2;
  const nextParams = { ...params, pageNumber: nextPage };

  return [...data, ...(await getRecursivelyLabelsApi(projectId, nextParams, workspaceId))];
};

export const getLabelsCSVApi = (
  projectId: string,
  workspaceId: string = getCurrentWorkspaceId(),
) => {
  return http.get(`/api/v2/workspaces/${workspaceId}/labeling_projects/${projectId}/export`, {
    responseType: 'blob',
    headers: {
      Accept: MIMETypes.CSV,
    },
  });
};
