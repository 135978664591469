import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  DislikeFilled,
  DislikeOutlined,
  FlagFilled,
  FlagOutlined,
  HistoryOutlined,
  LikeFilled,
  LikeOutlined,
} from '@ant-design/icons';
import { Button, Progress, Switch } from 'antd';
import { getODataEQFilterFrom } from '@utils/odata';
import useEffectUpdateOnly from '@hooks/useEffectUpdateOnly';
import { StatusCodes } from '@constants/enum/common';
import {
  FLAG_TO_REVIEW_TOOLTIP,
  NEXT_QUERY_LABEL,
  PREV_QUERY_LABEL,
  RESULTS_LABELED_LABEL,
  SHOW_DOCUMENT_METADATA_LABEL,
} from '@constants/labeling';
import { NOT_RELEVANT_BUTTON_LABEL, RELEVANT_BUTTON_LABEL } from '@constants/search';
import {
  createDocumentRetrievalLabel,
  getLabelingProjectLabelsAsLabeledResults,
  getLabelingProjectLabelsAsSearchResult,
  getLabelingProjectStats,
  resetLabelsAsSearchResult,
  updateDocumentRetrievalLabel,
} from '@redux/actions/labelingActions';
import { resetPipelineLatestQueries } from '@redux/actions/pipelineActions';
import { resetResults, search } from '@redux/actions/searchActions';
import {
  labeledResultsSelector,
  labelingProjectLabelsAsSearchResultSelector,
  labelingProjectLabelsAsSearchResultStatusSelector,
} from '@redux/selectors/labelingSelectors';
import {
  searchMessageSelector,
  searchResultSelector,
  searchStatusSelector,
} from '@redux/selectors/searchSelectors';
import {
  IMessage,
  IPipeline,
  ISearchResult,
  ISearchResultAnswer,
  ISearchResultDocument,
  LabelRelevance,
} from '@redux/types/types';
import SearchCardLayout from '@components/search/layouts/searchCard/SearchCardLayout';
import SearchActionButton from '@components/search/molecules/searchActionButton/SearchActionButton';
import SearchQueryInput from '@components/search/molecules/searchQueryInput/SearchQueryInput';
import SearchResultsContainer from '@components/search/organisms/results/SearchResultsContainer';
import LabelingQueryHistory from '@pages/labelingQuery/components/LabelingQueryHistory';
import styles from './labelingSearch.module.scss';
import SearchNoResultsContainer from '../results/SearchNoResultsContainer';

const SEARCH_HISTORY_LABELS_LIMIT = 20;

const enum TraverseDirrection {
  FORWARD = 'FORWARD',
  BACKWARD = 'BACKWARD',
}

export interface LabelingSearchProps {
  selectedPipeline: IPipeline;
  projectId: string;
}

// TODO: Remove search history on -> DC-614

// TODO: To discuss: use a wrapper or hooks for logic that we want to reuse search components
const LabelingSearch = ({ selectedPipeline, projectId }: LabelingSearchProps) => {
  const dispatch = useDispatch();
  const searchInputQuery = useRef('');
  const searchStatus = useSelector(searchStatusSelector);
  const searchResult = useSelector(searchResultSelector);
  const labeledResults = useSelector(labeledResultsSelector);
  const labelsAsSearchResult = useSelector(labelingProjectLabelsAsSearchResultSelector);
  const labelsAsSearchResultStatus = useSelector(labelingProjectLabelsAsSearchResultStatusSelector);
  const [sessionQueries, setSessionQueries] = useState<string[]>([]);
  const [historySearchResults, setHistorySearchResults] = useState<Record<string, ISearchResult>>(
    {},
  );
  const [currentQueryPosition, setCurrentQueryPosition] = useState(0);
  const [showDocumentMetadata, setShowDocumentMetadata] = useState<boolean>(false);
  const [labelingQueryHistoryVisible, setLabelingQueryHistoryVisible] = useState<boolean>(false);

  // Todo: Provide more context to this state (maybe refactor)
  const message: IMessage = useSelector(searchMessageSelector);

  useEffect(() => {
    dispatch(resetPipelineLatestQueries);
    dispatch(resetLabelsAsSearchResult);
  }, []);

  useEffectUpdateOnly(() => {
    const { query_id: queryId, query, documents } = searchResult;
    if (!queryId) return;

    // Track current session queries and update position
    const updatedSessionQueries = [...sessionQueries, queryId];
    setSessionQueries(updatedSessionQueries);
    setCurrentQueryPosition(updatedSessionQueries.length - 1);

    // Send query results as unlabeled labels (to generate query history)
    if (searchStatus === StatusCodes.SUCCESS) {
      documents.forEach((result, idx) => {
        const { result_id: resultId } = result;
        const { labelId } = labeledResults[resultId] || {};

        if (!labelId) {
          dispatch(
            createDocumentRetrievalLabel({
              projectId,
              query,
              queryId,
              result,
              rank: idx,
            }),
          );
        }
      });
    }
  }, [searchResult]);

  useEffectUpdateOnly(() => {
    if (labelsAsSearchResult) {
      setHistorySearchResults({
        ...historySearchResults,
        [labelsAsSearchResult.query_id]: labelsAsSearchResult,
      });
    }
  }, [labelsAsSearchResult]);

  const getSearchHistoryLabelsAsLabeledResults = (queryId: string) => {
    const filter = getODataEQFilterFrom('query_id', queryId);
    dispatch(
      getLabelingProjectLabelsAsLabeledResults({
        projectId,
        filter,
        limit: SEARCH_HISTORY_LABELS_LIMIT,
      }),
    );
  };

  const getLabelsAsSearchResult = (queryId: string) => {
    const params = {
      projectId,
      filter: getODataEQFilterFrom('query_id', queryId),
      limit: SEARCH_HISTORY_LABELS_LIMIT,
    };
    dispatch(getLabelingProjectLabelsAsSearchResult(params));
  };

  const getSearchHistoryResultsQuery = () => {
    const currentQueryId = sessionQueries[currentQueryPosition];
    if (!historySearchResults[currentQueryId]) return '';
    const { query } = historySearchResults[currentQueryId];
    return query;
  };

  const getSearchResults = () => {
    const currentQueryId = sessionQueries[currentQueryPosition];
    const results = historySearchResults[currentQueryId] || searchResult;
    return results;
  };

  const isEmptySearchResults = () => {
    const { answers, documents } = getSearchResults();
    return !answers.length && !documents.length;
  };

  const isSearching = () => {
    return (
      searchStatus === StatusCodes.IN_PROGRESS ||
      labelsAsSearchResultStatus === StatusCodes.IN_PROGRESS
    );
  };

  const thereAreMoreThanOneQueryMade = () => {
    const numberOfQueriesMade = sessionQueries.length;
    return numberOfQueriesMade > 1;
  };

  const isPrevQueryDisabled = () => {
    const isLoading = labelsAsSearchResultStatus === StatusCodes.IN_PROGRESS;
    return !thereAreMoreThanOneQueryMade() || currentQueryPosition === 0 || isLoading;
  };

  const isNextQueryDisabled = () => {
    const numberOfQueriesMade = sessionQueries.length;
    const isLoading = labelsAsSearchResultStatus === StatusCodes.IN_PROGRESS;
    return (
      !thereAreMoreThanOneQueryMade() ||
      numberOfQueriesMade - 1 === currentQueryPosition ||
      isLoading
    );
  };

  const getSearchStatus = () => {
    const currentQueryId = sessionQueries[currentQueryPosition];
    if (!historySearchResults[currentQueryId] || isSearching()) return searchStatus;
    return labelsAsSearchResultStatus;
  };

  const getTotalLabeledResults = () => {
    const { documents: documentResults } = getSearchResults();
    const labeledResultsIds = Object.keys(labeledResults);
    return labeledResultsIds.filter((labeledResultId) =>
      documentResults.find(
        ({ result_id: resultId }) =>
          labeledResultId === resultId && labeledResults[labeledResultId].relevance,
      ),
    ).length;
  };

  const onClickSearch = async (query: string) => {
    searchInputQuery.current = query;
    dispatch(search({ pipelineName: selectedPipeline.name, query }));
  };

  const onLabelingButtonPressed = async (
    relevance: LabelRelevance,
    result: ISearchResultDocument,
    resultIdx: number,
  ) => {
    const { query, query_id: queryId } = getSearchResults() || {};
    const { result_id: resultId } = result;
    const { relevance: labeledRelevance, labelId } = labeledResults[resultId] || {};

    if (labeledRelevance === relevance) return;

    if (labelId) {
      await dispatch(
        updateDocumentRetrievalLabel({
          projectId,
          resultId,
          labelId,
          relevance,
        }),
      );
      dispatch(getLabelingProjectStats(projectId));
      return;
    }

    await dispatch(
      createDocumentRetrievalLabel({
        relevance,
        projectId,
        query,
        queryId,
        result,
        rank: resultIdx,
      }),
    );

    dispatch(getLabelingProjectStats(projectId));
  };

  const onShowMetadataChanged = () => {
    setShowDocumentMetadata(!showDocumentMetadata);
  };

  const handleSelectingHistoryQuery = (queryId: string) => {
    dispatch(resetResults);
    setSessionQueries([queryId]);
    setCurrentQueryPosition(0);
    getLabelsAsSearchResult(queryId);
    getSearchHistoryLabelsAsLabeledResults(queryId);
  };

  // Queries Traverse

  const onTraverseSearchQueries = (direction: TraverseDirrection) => {
    const nextQueryPosition =
      direction === TraverseDirrection.FORWARD
        ? currentQueryPosition + 1
        : currentQueryPosition - 1;
    const nextQueryId = sessionQueries[nextQueryPosition];
    getLabelsAsSearchResult(nextQueryId);
    setCurrentQueryPosition(nextQueryPosition);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'ArrowLeft':
        if (isPrevQueryDisabled()) break;
        onTraverseSearchQueries(TraverseDirrection.BACKWARD);
        break;
      case 'ArrowRight':
        if (isNextQueryDisabled()) break;
        onTraverseSearchQueries(TraverseDirrection.FORWARD);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  // Renders

  const renderSearchBarContent = () => {
    return (
      <div className={styles.searchBar}>
        <Button
          size="large"
          icon={<HistoryOutlined />}
          onClick={() => setLabelingQueryHistoryVisible(true)}
        />
        <SearchQueryInput
          query={getSearchHistoryResultsQuery()}
          onQuerySubmit={onClickSearch}
          loading={isSearching()}
          disabled={!selectedPipeline || isSearching()}
        />
      </div>
    );
  };

  const renderFooterContent = () => {
    return (
      <div
        className={`${styles.footer} ${
          !thereAreMoreThanOneQueryMade() ? styles.footer_center : ''
        }`}
      >
        {thereAreMoreThanOneQueryMade() && (
          <Button
            type="text"
            size="small"
            disabled={isPrevQueryDisabled()}
            icon={<ArrowLeftOutlined />}
            onClick={() => onTraverseSearchQueries(TraverseDirrection.BACKWARD)}
          >
            {PREV_QUERY_LABEL}
          </Button>
        )}
        <h6 className={styles.footer_title}>{searchInputQuery.current || selectedPipeline.name}</h6>
        {thereAreMoreThanOneQueryMade() && (
          <Button
            type="text"
            size="small"
            disabled={isNextQueryDisabled()}
            onClick={() => onTraverseSearchQueries(TraverseDirrection.FORWARD)}
          >
            {NEXT_QUERY_LABEL} <ArrowRightOutlined />
          </Button>
        )}
      </div>
    );
  };

  const renderSearchResultsHeader = () => {
    const { documents: documentResults } = getSearchResults();
    const totalResults = documentResults.length;
    const totalResultsLabeled = getTotalLabeledResults();
    const isLabelTargetReached = totalResults > 0 && totalResults === totalResultsLabeled;
    const currentLabelColor = isLabelTargetReached ? styles.successColor : styles.infoColor;

    return (
      <div className={styles.resultsHeader}>
        <div className={styles.resultsHeader_totalResultsLabeled}>
          <span className={styles.resultsHeader_totalResultsLabeled_label}>
            {RESULTS_LABELED_LABEL}
          </span>
          <Progress
            percent={(totalResultsLabeled / totalResults) * 100}
            status={isLabelTargetReached ? 'success' : 'normal'}
            showInfo={isLabelTargetReached}
          />
          <span className={styles.resultsHeader_totalResultsLabeled_label}>
            <strong className={totalResultsLabeled > 0 ? currentLabelColor : ''}>
              {totalResultsLabeled}
            </strong>
            <span>/{totalResults}</span>
          </span>
        </div>
        <div className={styles.resultsHeader_showMetadata}>
          <span>{SHOW_DOCUMENT_METADATA_LABEL}</span>
          <Switch checked={showDocumentMetadata} size="small" onChange={onShowMetadataChanged} />
        </div>
      </div>
    );
  };

  const renderSearchResultLabelingButtons = (
    result: ISearchResultDocument | ISearchResultAnswer,
    idx: number,
  ) => {
    if (!result || 'answer' in result) return null;
    const { relevance: labeledRelevance } = labeledResults[result.result_id] || {};
    return (
      <div className={styles.resultLabelingButtons}>
        <SearchActionButton
          tooltipTitle=""
          active={labeledRelevance === LabelRelevance.RELEVANT}
          onClick={() => onLabelingButtonPressed(LabelRelevance.RELEVANT, result, idx)}
          icon={<LikeOutlined />}
          activeIcon={<LikeFilled />}
          label={RELEVANT_BUTTON_LABEL}
        />
        <SearchActionButton
          tooltipTitle=""
          active={labeledRelevance === LabelRelevance.NOT_RELEVANT}
          onClick={() => onLabelingButtonPressed(LabelRelevance.NOT_RELEVANT, result, idx)}
          icon={<DislikeOutlined />}
          activeIcon={<DislikeFilled />}
          label={NOT_RELEVANT_BUTTON_LABEL}
        />
        <SearchActionButton
          tooltipTitle={FLAG_TO_REVIEW_TOOLTIP}
          active={labeledRelevance === LabelRelevance.UNDECIDED}
          danger={labeledRelevance === LabelRelevance.UNDECIDED}
          onClick={() => onLabelingButtonPressed(LabelRelevance.UNDECIDED, result, idx)}
          icon={<FlagOutlined />}
          activeIcon={<FlagFilled className={styles.errorColor} />}
        />
      </div>
    );
  };

  return (
    <>
      <LabelingQueryHistory
        open={labelingQueryHistoryVisible}
        projectId={projectId}
        currentQueryId={sessionQueries[currentQueryPosition]}
        onCancel={() => setLabelingQueryHistoryVisible(false)}
        onDone={() => setLabelingQueryHistoryVisible(false)}
        onQuerySelect={handleSelectingHistoryQuery}
      />
      <SearchCardLayout
        searchBar={renderSearchBarContent()}
        body={
          !isEmptySearchResults() ? (
            <SearchResultsContainer
              results={getSearchResults()}
              status={getSearchStatus()}
              query={searchInputQuery.current}
              pipelineName={selectedPipeline.name}
              pipelineId={selectedPipeline.pipeline_id}
              header={renderSearchResultsHeader()}
              resultFeedback={renderSearchResultLabelingButtons}
              displayMetadata={showDocumentMetadata}
              displayRelevanceScore={false}
              documentResultOptions={{
                displayFullDocument: true,
              }}
            />
          ) : (
            <SearchNoResultsContainer
              selectedPipeline={selectedPipeline}
              status={getSearchStatus()}
              query={searchInputQuery.current}
              message={message}
            />
          )
        }
        footer={renderFooterContent()}
      />
    </>
  );
};

export default LabelingSearch;
