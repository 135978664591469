import React, { useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import styles from './textEditorViewer.module.scss';

interface ITextEditorViewerProps {
  content: string;
}

const TextEditorViewer = ({ content }: ITextEditorViewerProps) => {
  const editor = useRef<Quill>();
  const descriptionEl = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editor.current) return;

    editor.current = new Quill(descriptionEl.current!, {
      modules: {
        toolbar: false,
      },
      readOnly: true,
      theme: 'snow',
    });

    const sanitizedContent = DOMPurify.sanitize(content);
    editor.current.clipboard.dangerouslyPasteHTML(sanitizedContent);
  }, []);

  return <div className={styles.textEditorViewer} ref={descriptionEl} />;
};

export default TextEditorViewer;
