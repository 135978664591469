import React from 'react';
import { Progress } from 'antd';
import { interpolateString } from '@utils/string';
import CheckCircleFilled from '@assets/icons/CheckCircleFilled.svg?react';
import {
  LABELING_PROGRESS_QUERIES_LABEL,
  LABELING_PROGRESS_QUERY_TARGET_DESCRIPTION,
  LABELING_PROGRESS_QUERY_TARGET_LABEL,
  LABELING_PROGRESS_QUERY_TARGET_POST_DESCRIPTION,
} from '@constants/labeling';
import styles from './labelingProgress.module.scss';

interface ILabelingProgressProps {
  totalLabeledQueries: number;
  queryTarget?: number | null;
}

const LabelingProgress = ({ totalLabeledQueries, queryTarget }: ILabelingProgressProps) => {
  const queryTargetDefined = queryTarget && queryTarget !== 0;
  const percent = queryTarget ? (totalLabeledQueries / queryTarget) * 100 : 0;
  const isTargetReached = percent >= 100;

  return (
    <div className={`${styles.labelingProgress} ${isTargetReached ? styles.success : ''}`}>
      {!!queryTargetDefined && (
        <>
          {isTargetReached ? (
            <CheckCircleFilled className={styles.queryTargetSuccess} />
          ) : (
            <Progress
              type="circle"
              percent={percent}
              size={32}
              strokeWidth={12}
              showInfo={false}
              trailColor="#ffffff"
            />
          )}
        </>
      )}
      <div className={styles.target}>
        <div className={styles.target_header}>
          {queryTargetDefined
            ? LABELING_PROGRESS_QUERY_TARGET_LABEL
            : LABELING_PROGRESS_QUERIES_LABEL}
        </div>
        <div className={styles.target_info}>
          {interpolateString(LABELING_PROGRESS_QUERY_TARGET_DESCRIPTION, {
            queriesPerformed: (
              <span
                className={`${styles.target_info_queries} ${isTargetReached ? styles.success : ''}`}
              >
                {totalLabeledQueries}
              </span>
            ),
          })}
          {!!queryTargetDefined &&
            interpolateString(LABELING_PROGRESS_QUERY_TARGET_POST_DESCRIPTION, {
              queryTarget,
            })}
        </div>
      </div>
    </div>
  );
};

export default LabelingProgress;
