import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AreaChartOutlined, DownOutlined, ReloadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space, Tooltip } from 'antd';
import { ButtonProps } from 'antd/es/button';
import dayjs from 'dayjs';
import { isPipelineIndexed, pipelineInProgress } from '@utils/pipelines';
import { getUserFullname } from '@utils/transformation/user';
import MoreOutlined from '@assets/icons/more-outlined.svg?react';
import {
  CANCEL_BUTTON_LABEL,
  DELETE_BUTTON_LABEL,
  DEPLOY_BUTTON_LABEL,
  DUPLICATE_BUTTON_LABEL,
  EDIT_MENU_LABEL,
  EDIT_PIPELINE_OPTIONS,
  EditPipelineOptionsKeysType,
  OBSERVABILITY_DASHBOARD_TOOLTIP,
  SHARE_BUTTON_LABEL,
  TRY_AGAIN_BUTTON_LABEL,
  UNDEPLOY_BUTTON_LABEL,
} from '@constants/details-header';
import {
  ACTIVATE_PIPELINE_BUTTON_LABEL,
  DEPLOYMENT_FAILED_LABEL,
  EXPORT_PIPELINE_DOCUMENTS_LABEL,
  PARTIALLY_INDEXED_LABEL,
  PipelineDesiredStatusCodes,
  PipelineOutputType,
  PipelineStatusCodes,
  PROMPT_EXPLORER_BUTTON_LABEL,
  RENAME_BUTTON_LABEL,
  UNDEPLOYMENT_FAILED_LABEL,
} from '@constants/pipelines';
import { DeepsetCloudVersion, IPipeline, IPipelineIndexingData } from '@redux/types/types';
import DetailsHeader from '@components/detailsHeader/detailsHeader';
import PipelineConfirmationModal from '@components/pipelines/pipelineConfirmationModal/PipelineConfirmationModal';
import PipelineStatusTag from '@components/pipelineStatusTag/PipelineStatusTag';
import styles from './pipelineDetailsHeader.module.scss';

enum MenuActions {
  Duplicate = 'DUPLICATE',
  Delete = 'DELETE',
  PromptExplorer = 'PROMPT_EXPLORER',
  RenamePipeline = 'RENAME',
  Undeploy = 'UNDEPLOY',
  ExportDocuments = 'EXPORT_DOCUMENTS',
}

interface IPipelineDetailsHeader {
  pipeline: IPipeline;
  pipelineName: string;
  deployPipelineHandler: (name: string) => void;
  undeployPipelineHandler: (name: string) => void;
  deletePipelineHandler: (name: string) => void;
  goToPipelinesPageHandler: () => void;
  fetchPipelineIndexingHandler: (name: string) => void;
  fetchPipelineHandler: (name: string) => void;
  pipelineIndexingData: IPipelineIndexingData;
  goToYamlEditor: () => void;
  goToStudio?: () => void;
  duplicatePipeline: (name: string) => void;
  onPromptExplorerMenuClick: () => void;
  setShowRenamePipelineModal: (show: boolean) => void;
  onSharePipelineHandler: () => void;
  onActivatePipelineHandler: (name: string) => void;
  pipelineStatusString: string;
  serviceLevel?: React.ReactNode;
  deepsetCloudVersion?: React.ReactNode;
  onDocumentExportMenuClicked: () => void;
}

const PipelineDetailsHeader = (props: IPipelineDetailsHeader) => {
  const navigate = useNavigate();
  const {
    pipeline,
    pipelineName,
    deployPipelineHandler,
    undeployPipelineHandler,
    deletePipelineHandler,
    goToPipelinesPageHandler,
    fetchPipelineIndexingHandler,
    fetchPipelineHandler,
    pipelineIndexingData,
    goToYamlEditor,
    goToStudio,
    duplicatePipeline,
    onPromptExplorerMenuClick,
    setShowRenamePipelineModal,
    onSharePipelineHandler,
    onActivatePipelineHandler,
    pipelineStatusString,
    serviceLevel,
    deepsetCloudVersion,
    onDocumentExportMenuClicked,
  } = props;

  const editPipelineNameButtonRef = useRef<HTMLButtonElement>(null);
  const observabilityButtonRef = useRef<HTMLButtonElement>(null);
  const [openPipelineConfirmationModal, setOpenPipelineConfirmationModal] = useState(false);
  const [undeployingPipeline, setUndeployingPipeline] = useState(false);

  const onUndeployPipeline = async () => {
    setUndeployingPipeline(true);
    setOpenPipelineConfirmationModal(true);
  };

  const onDeployPipeline = async () => {
    await deployPipelineHandler(pipelineName!);
    fetchPipelineHandler(pipelineName);
  };

  const onSharePipeline = async () => {
    await onSharePipelineHandler();
  };

  const onActivatePipeline = async () => {
    await onActivatePipelineHandler(pipelineName!);
    fetchPipelineHandler(pipelineName);
  };

  const handleUndeployRun = async () => {
    await undeployPipelineHandler(pipelineName!);
    fetchPipelineHandler(pipelineName);
    setOpenPipelineConfirmationModal(false);
    setUndeployingPipeline(false);
  };

  const handleDeleteRun = async () => {
    await deletePipelineHandler(pipelineName!);
    goToPipelinesPageHandler();
  };

  const deployButton = () => {
    if (pipeline.status === PipelineStatusCodes.ACTIVATING) return null;

    let buttonProps: { label: string; action: () => Promise<void> } & ButtonProps = {
      label: DEPLOY_BUTTON_LABEL,
      action: onDeployPipeline,
      loading: pipelineInProgress(pipeline.status),
      danger: false,
      icon: null,
    };

    if (pipeline.desired_status === PipelineDesiredStatusCodes.DEPLOYED) {
      buttonProps = {
        ...buttonProps,
        label: SHARE_BUTTON_LABEL,
        action: onSharePipeline,
        icon: null,
      };
    }

    if (pipeline.status === PipelineStatusCodes.IDLE) {
      buttonProps = {
        ...buttonProps,
        label: ACTIVATE_PIPELINE_BUTTON_LABEL,
        action: onActivatePipeline,
        icon: null,
      };
    }

    if (pipelineInProgress(pipeline.status))
      buttonProps = {
        ...buttonProps,
        label: CANCEL_BUTTON_LABEL,
        action:
          pipeline.status === PipelineStatusCodes.DEPLOYMENT_IN_PROGRESS ||
          pipeline.status === PipelineStatusCodes.DEPLOYMENT_SCHEDULED
            ? onUndeployPipeline
            : onDeployPipeline,
        loading: false,
        danger: true,
        icon: null,
      };

    if (
      pipelineStatusString === DEPLOYMENT_FAILED_LABEL ||
      pipelineStatusString === PARTIALLY_INDEXED_LABEL
    )
      buttonProps = {
        label: TRY_AGAIN_BUTTON_LABEL,
        action: onDeployPipeline,
        loading: pipelineInProgress(pipeline.status),
        danger: false,
        icon: <ReloadOutlined />,
      };

    if (pipelineStatusString === UNDEPLOYMENT_FAILED_LABEL)
      buttonProps = {
        label: TRY_AGAIN_BUTTON_LABEL,
        action: onUndeployPipeline,
        loading: pipelineInProgress(pipeline.status),
        danger: false,
        icon: <ReloadOutlined />,
      };

    return (
      <Button
        onClick={() => buttonProps.action()}
        loading={buttonProps.loading}
        danger={buttonProps.danger}
        icon={buttonProps.icon}
      >
        {buttonProps.label}
      </Button>
    );
  };

  const handleMoreActionClick = (key: string) => {
    if (key === MenuActions.Duplicate) duplicatePipeline(pipelineName);
    if (key === MenuActions.PromptExplorer) onPromptExplorerMenuClick();
    if (key === MenuActions.RenamePipeline) setShowRenamePipelineModal(true);
    if (key === MenuActions.Undeploy) onUndeployPipeline();
    if (key === MenuActions.Delete) setOpenPipelineConfirmationModal(true);
    if (key === MenuActions.ExportDocuments) onDocumentExportMenuClicked();
  };

  const actionMenuItems = [
    {
      label: RENAME_BUTTON_LABEL,
      key: MenuActions.RenamePipeline,
    },
    {
      label: DUPLICATE_BUTTON_LABEL,
      key: MenuActions.Duplicate,
    },
    ...(pipeline.status === PipelineStatusCodes.DEPLOYED && pipeline.supports_prompt
      ? [
          {
            label: PROMPT_EXPLORER_BUTTON_LABEL,
            key: MenuActions.PromptExplorer,
          },
        ]
      : []),
    ...(isPipelineIndexed(pipelineIndexingData)
      ? [
          {
            label: EXPORT_PIPELINE_DOCUMENTS_LABEL,
            key: MenuActions.ExportDocuments,
          },
        ]
      : []),
    ...(pipeline.desired_status === PipelineDesiredStatusCodes.DEPLOYED
      ? [
          {
            label: UNDEPLOY_BUTTON_LABEL,
            key: MenuActions.Undeploy,
          },
        ]
      : []),
    {
      label: DELETE_BUTTON_LABEL,
      key: MenuActions.Delete,
      danger: true,
    },
  ];

  const getIndexingDetails = (name: string) => {
    fetchPipelineIndexingHandler(name);
  };

  const handleMenuEditPipelineClick = (key: string) => {
    if (key === EditPipelineOptionsKeysType.YAML) {
      goToYamlEditor();
    } else if (key === EditPipelineOptionsKeysType.STUDIO && goToStudio) {
      goToStudio();
    }
  };

  const renderEditButton = () => {
    if (goToStudio && pipeline.deepset_cloud_version === DeepsetCloudVersion.V2) {
      return (
        <Dropdown
          menu={{
            items: EDIT_PIPELINE_OPTIONS,
            onClick: (e) => handleMenuEditPipelineClick(e.key),
          }}
          trigger={['click']}
        >
          <Button>
            <Space>
              {EDIT_MENU_LABEL}
              <DownOutlined />
            </Space>
          </Button>
        </Dropdown>
      );
    }

    return (
      <Button
        onClick={() => {
          goToYamlEditor();
        }}
      >
        {EDIT_MENU_LABEL}
      </Button>
    );
  };

  const renderActions = () => (
    <>
      {renderEditButton()}
      {deployButton()}
      {pipeline.output_type === PipelineOutputType.GENERATIVE && (
        <>
          <Tooltip title={OBSERVABILITY_DASHBOARD_TOOLTIP}>
            <Button
              ref={observabilityButtonRef}
              icon={<AreaChartOutlined />}
              onClick={() => {
                navigate(`/groundedness/${pipelineName}`);
              }}
              className={styles.headerActions_button}
            />
          </Tooltip>
        </>
      )}
      <Dropdown
        menu={{ items: actionMenuItems, onClick: ({ key }) => handleMoreActionClick(key) }}
        placement="bottomRight"
        trigger={['click']}
      >
        <Button
          ref={editPipelineNameButtonRef}
          className={styles.moreActions_button}
          icon={<MoreOutlined />}
        />
      </Dropdown>
    </>
  );

  return (
    <>
      <DetailsHeader
        title={pipeline.name}
        subtitle={dayjs(pipeline.created_at).format('DD/MM/YYYY HH:mm')}
        createdBy={getUserFullname(pipeline.created_by)}
        actions={renderActions()}
        status={
          <PipelineStatusTag
            pipeline={pipeline as IPipeline}
            getIndexingDetails={getIndexingDetails}
            indexingData={pipelineIndexingData}
          />
        }
        serviceLevel={serviceLevel}
        deepsetCloudVersion={deepsetCloudVersion}
      />
      <PipelineConfirmationModal
        open={openPipelineConfirmationModal}
        okText={undeployingPipeline ? UNDEPLOY_BUTTON_LABEL : DELETE_BUTTON_LABEL}
        onOk={async () => {
          if (undeployingPipeline) handleUndeployRun();
          else handleDeleteRun();
        }}
        cancelText={CANCEL_BUTTON_LABEL}
        onCancel={() => {
          setOpenPipelineConfirmationModal(false);
          setUndeployingPipeline(false);
        }}
        pipelines={openPipelineConfirmationModal ? [pipeline] : []}
        undeployingPipeline={undeployingPipeline}
      />
    </>
  );
};

export default PipelineDetailsHeader;
