import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { fetchPipeline } from '@redux/actions/pipelineActions';
import { resetResults } from '@redux/actions/searchActions';
import { pipelineSelector } from '@redux/selectors/pipelineSelectors';
import { IPipeline } from '@redux/types/types';
import LabelingSearch from '@components/search/organisms/search/LabelingSearch';
import LabelingSidebar from './components/LabelingSidebar';
import styles from './labelingQueryPage.module.scss';

const LabelingQueryPage = () => {
  const dispatch = useDispatch();
  const { pipelineName, projectId } = useParams() as {
    pipelineName: string;
    projectId: string;
  };
  const pipeline: IPipeline = useSelector(pipelineSelector);

  useEffect(() => {
    dispatch(resetResults);
    if (pipelineName) dispatch(fetchPipeline({ pipelineName }));

    return () => {
      dispatch(resetResults);
    };
  }, []);

  return (
    <div className={styles.labelingQueryPage}>
      <LabelingSearch selectedPipeline={pipeline} projectId={projectId} />
      <LabelingSidebar projectId={projectId} />
    </div>
  );
};

export default LabelingQueryPage;
