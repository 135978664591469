import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import { Button, Modal } from 'antd';
import { interpolateString } from '@utils/string';
import { DOCUMENTATION_LINK_LABEL } from '@constants/common';
import { StatusCodes } from '@constants/enum/common';
import {
  DELETE_PROJECT_MODAL,
  DOCS_LABELING_LINK,
  EMPTY_PROJECTS_LIST_PAGE,
  NEW_PROJECT_BUTTON_LABEL,
  PROJECT_MORE_OPTIONS_MENU_ITEMS,
  ProjectMoreOptionMenuAction,
  PROJECTS_TITLE,
} from '@constants/labeling';
import {
  createLabelingProject,
  deleteLabelingProject,
  exportLabels,
  getLabelingProjects,
  updateLabelingProject,
} from '@redux/actions/labelingActions';
import {
  createLabelingProjectStatusSelector,
  labelingProjectsSelector,
  updateProjectStatusSelector,
} from '@redux/selectors/labelingSelectors';
import { IAPIPaginationData, ILabelingProject } from '@redux/types/types';
import CreateUpdateLabelingProjectModal from '@components/createUpdateLabelingProjectModal/CreateUpdateLabelingProjectModal';
import EmptyPageDescription from '@components/emptyPageDescription/EmptyPageDescription';
import ProjectCard from './components/ProjectCard';
import styles from './labelingPage.module.scss';

const { confirm } = Modal;
const LabelingLandingSVG = React.lazy(() => import('@assets/empty/labeling-landing.svg?react'));

const LabelingPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: projects }: IAPIPaginationData<ILabelingProject[]> =
    useSelector(labelingProjectsSelector);
  const updateProjectStatus = useSelector(updateProjectStatusSelector);
  const createLabelingProjectStatus = useSelector(createLabelingProjectStatusSelector);

  const [createProjectModalVisible, setCreateProjectModalVisible] = useState(false);
  const [editProjectValues, setEditProjectValues] = useState<{
    name: string;
    description?: string;
    id: string;
  } | null>(null);

  const isUpdatingCreatingProject =
    createLabelingProjectStatus === StatusCodes.IN_PROGRESS ||
    updateProjectStatus === StatusCodes.IN_PROGRESS;

  useEffect(() => {
    dispatch(getLabelingProjects());
  }, []);

  useEffect(() => {
    if (
      createLabelingProjectStatus === StatusCodes.SUCCESS ||
      updateProjectStatus === StatusCodes.SUCCESS
    ) {
      dispatch(getLabelingProjects());
      setCreateProjectModalVisible(false);
    }
  }, [createLabelingProjectStatus, updateProjectStatus]);

  const handleCreateProjectButtonClick = () => {
    setCreateProjectModalVisible(true);
  };

  const handleClosingCreateUpdateProjectModal = () => {
    setEditProjectValues(null);
    setCreateProjectModalVisible(false);
  };

  const handleCreatingUpdatingProject = (newProject: {
    name: string;
    description?: string;
    id?: string;
  }) => {
    setEditProjectValues(null);
    if (newProject.id)
      dispatch(updateLabelingProject({ projectId: newProject.id, projectBody: newProject }));
    else dispatch(createLabelingProject(newProject));
  };

  const handleNavigatingToProjectDetailsPage = (projectId: string) => {
    navigate(`/labeling/${projectId}`);
  };

  const handleProjectMoreOptionsMenuClick = async (key: string, project: ILabelingProject) => {
    const { name, description, project_id: id } = project;
    switch (key) {
      case ProjectMoreOptionMenuAction.Edit:
        setEditProjectValues({ name, description, id } || null);
        setCreateProjectModalVisible(true);
        break;
      case ProjectMoreOptionMenuAction.Export:
        dispatch(exportLabels({ projectName: name, projectId: id }));
        break;
      case ProjectMoreOptionMenuAction.Duplicate: {
        const temp = { ...project, name: `(Copy) ${name}` };
        await dispatch(createLabelingProject(temp));
        dispatch(getLabelingProjects());
        break;
      }
      case ProjectMoreOptionMenuAction.Delete:
        confirm({
          className: styles.deleteConfirmModal,
          title: DELETE_PROJECT_MODAL.title,
          // eslint-disable-next-line react/jsx-pascal-case
          icon: <DELETE_PROJECT_MODAL.icon />,
          content: interpolateString(DELETE_PROJECT_MODAL.description, {
            projectName: <strong>{name}</strong>,
          }),
          onOk: async () => {
            await dispatch(deleteLabelingProject(id));
            dispatch(getLabelingProjects());
          },
          okType: DELETE_PROJECT_MODAL.okType,
          okText: DELETE_PROJECT_MODAL.okText,
        });
        break;
      default:
        break;
    }
  };

  const renderNewProjectButton = () => {
    return (
      <Button type="primary" icon={<PlusOutlined />} onClick={handleCreateProjectButtonClick}>
        {NEW_PROJECT_BUTTON_LABEL}
      </Button>
    );
  };

  const renderEmptyProjectList = () => {
    return (
      <EmptyPageDescription
        title={EMPTY_PROJECTS_LIST_PAGE.TITLE}
        description={EMPTY_PROJECTS_LIST_PAGE.DESCRIPTION.map(({ paragraph }) => ({
          paragraph: interpolateString(paragraph, {
            documentationLink: (
              <a href={DOCS_LABELING_LINK} target="_blank" rel="noreferrer">
                {DOCUMENTATION_LINK_LABEL}
              </a>
            ),
          }),
        }))}
        image={<LabelingLandingSVG />}
        action={renderNewProjectButton()}
      />
    );
  };

  const renderProjectList = () => {
    return projects.map((project) => {
      const {
        name,
        project_id: id,
        query_target: queryTarget,
        total_labels: totalLabels,
        labeled_queries: labeledQueries,
      } = project;
      return (
        <ProjectCard
          key={id}
          name={name}
          queryTarget={queryTarget || 0}
          totalLabels={totalLabels || 0}
          totalQueries={labeledQueries || 0}
          onClick={() => handleNavigatingToProjectDetailsPage(id)}
          moreOptionsMenu={{
            items: PROJECT_MORE_OPTIONS_MENU_ITEMS,
            onClick: ({ key }) => handleProjectMoreOptionsMenuClick(key, project),
          }}
        />
      );
    });
  };

  return (
    <div className={styles.labelingPage}>
      <CreateUpdateLabelingProjectModal
        initialValues={editProjectValues}
        open={createProjectModalVisible}
        loading={isUpdatingCreatingProject}
        onCancel={handleClosingCreateUpdateProjectModal}
        onSubmit={handleCreatingUpdatingProject}
      />
      {projects.length > 0 ? (
        <div className={styles.labelingPage_container}>
          <div className={styles.labelingPage_header}>
            <h4>{PROJECTS_TITLE}</h4>
            {projects.length > 0 && renderNewProjectButton()}
          </div>
          <div className={styles.labelingPage_projectList}>{renderProjectList()}</div>
        </div>
      ) : (
        <div>{renderEmptyProjectList()}</div>
      )}
    </div>
  );
};

export default LabelingPage;
