import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DislikeTwoTone, LikeTwoTone, ReadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
  SIDEBAR_LABELING_GUIDELINE_DESCRIPTION,
  SIDEBAR_LABELING_GUIDELINE_TITLE,
  VIEW_LABELING_GUIDELINE_BUTTON_LABEL,
} from '@constants/labeling';
import { getLabelingProject, getLabelingProjectStats } from '@redux/actions/labelingActions';
import {
  labelingProjectSelector,
  labelingProjectStatsSelector,
} from '@redux/selectors/labelingSelectors';
import { ILabelingProject, ILabelingProjectStats } from '@redux/types/types';
import LabelingGuidelineModal from '@components/labelingGuidelineModal/LabelingGuidelineModal';
import LabelingLeaderboard from '@components/labelingLeaderboard/LabelingLeaderboard';
import LabelingProgress from '@components/labelingProgress/LabelingProgress';
import styles from './labelingSidebar.module.scss';

interface ILabelingSidebarProps {
  projectId: string;
}

const LabelingSidebar = (props: ILabelingSidebarProps) => {
  const dispatch = useDispatch();
  const { projectId } = props;
  const project: ILabelingProject = useSelector(labelingProjectSelector);
  const projectStats: ILabelingProjectStats = useSelector(labelingProjectStatsSelector);
  const [openGuidelines, setOpenGuidelines] = useState(false);

  useEffect(() => {
    dispatch(getLabelingProject(projectId));
    dispatch(getLabelingProjectStats(projectId));
  }, [projectId]);

  const { name, query_target: queryTarget } = project;
  const { labeled_queries_per_user: queriesPerUser, labeled_queries: totalLabels } = projectStats;

  return (
    <>
      <div className={styles.labelingSidebar}>
        <h5>{name}</h5>
        <div className={styles.labelingSidebar_guidelines}>
          <strong>
            <LikeTwoTone /> <DislikeTwoTone /> {SIDEBAR_LABELING_GUIDELINE_TITLE}
          </strong>
          <span>{SIDEBAR_LABELING_GUIDELINE_DESCRIPTION}</span>
          <Button
            type="primary"
            size="small"
            icon={<ReadOutlined />}
            onClick={() => setOpenGuidelines(true)}
            ghost
          >
            {VIEW_LABELING_GUIDELINE_BUTTON_LABEL}
          </Button>
        </div>
        <LabelingProgress totalLabeledQueries={totalLabels || 0} queryTarget={queryTarget} />
        <LabelingLeaderboard queriesPerUser={queriesPerUser} />
      </div>
      <LabelingGuidelineModal
        open={openGuidelines}
        project={project}
        readOnly
        onCancel={() => setOpenGuidelines(false)}
        onDone={() => setOpenGuidelines(false)}
      />
    </>
  );
};

export default LabelingSidebar;
