import React, { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import { CREATE_BUTTON_LABEL, OPTIONAL_LABEL, UPDATE_BUTTON_LABEL } from '@constants/common';
import {
  CREATE_NEW_PROJECT_MODAL_TITLE,
  DESCRIPTION_INPUT_LABEL,
  DESCRIPTION_INPUT_PLACEHOLDER,
  PROJECT_NAME_INPUT_LABEL,
  PROJECT_NAME_INPUT_PLACEHOLDER,
  UPDATE_PROJECT_MODAL_TITLE,
} from '@constants/labeling';
import styles from './createUpdateLabelingProjectModal.module.scss';

const { Item } = Form;
const { TextArea } = Input;

interface ICreateUpdateLabelingProjectModalProps {
  open: boolean;
  loading: boolean;
  onSubmit: (newProject: { name: string; description?: string; id?: string }) => void;
  initialValues: {
    name: string;
    description?: string;
    id: string;
  } | null;
  onCancel: () => void;
}

const CreateUpdateLabelingProjectModal = ({
  open,
  loading,
  initialValues,
  onSubmit,
  onCancel,
}: ICreateUpdateLabelingProjectModalProps) => {
  const [projectFormValues, setProjectFormValues] = useState({
    name: initialValues?.name || '',
    description: initialValues?.description || '',
  });

  useEffect(() => {
    if (initialValues) {
      setProjectFormValues({
        name: initialValues.name,
        description: initialValues.description || '',
      });
    }
  }, [initialValues]);

  const onFormValuesChange = (field: string, value: string | string[]) => {
    setProjectFormValues((prevValue) => ({
      ...prevValue,
      [field]: value,
    }));
  };

  const onModalVisibilityChange = (openState: boolean) => {
    if (!openState) setProjectFormValues({ name: '', description: '' });
  };

  const onCreateUpdateProject = () => {
    const params = {
      ...projectFormValues,
      id: initialValues?.id,
    };
    onSubmit(params);
  };

  const isValidForm = (): boolean => {
    const { name } = projectFormValues;
    return !!name;
  };

  return (
    <Modal
      title={initialValues?.id ? UPDATE_PROJECT_MODAL_TITLE : CREATE_NEW_PROJECT_MODAL_TITLE}
      okText={initialValues?.id ? UPDATE_BUTTON_LABEL : CREATE_BUTTON_LABEL}
      open={open}
      afterOpenChange={onModalVisibilityChange}
      className={styles.createUpdateProjectModal}
      centered
      onCancel={onCancel}
      onOk={onCreateUpdateProject}
      cancelButtonProps={{
        disabled: loading,
      }}
      okButtonProps={{
        disabled: !isValidForm() || loading,
        loading,
      }}
    >
      <Form layout="vertical">
        <Item label={PROJECT_NAME_INPUT_LABEL}>
          <Input
            value={projectFormValues.name}
            onChange={(event) => onFormValuesChange('name', event.target.value)}
            placeholder={PROJECT_NAME_INPUT_PLACEHOLDER}
          />
        </Item>
        <Item
          label={
            <>
              {DESCRIPTION_INPUT_LABEL}
              <span className={styles.createProjectModal_optionalLabel}> ({OPTIONAL_LABEL})</span>
            </>
          }
        >
          <TextArea
            rows={2}
            value={projectFormValues.description}
            onChange={(event) => onFormValuesChange('description', event.target.value)}
            placeholder={DESCRIPTION_INPUT_PLACEHOLDER}
            allowClear
          />
        </Item>
      </Form>
    </Modal>
  );
};

export default CreateUpdateLabelingProjectModal;
