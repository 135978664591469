import React from 'react';
import { MoreOutlined } from '@ant-design/icons';
import { Button, Dropdown, Progress } from 'antd';
import { MenuProps } from 'antd/lib/menu';
import { LABELS_LABEL, QUERIES_LABEL } from '@constants/labeling';
import styles from './projectCard.module.scss';

interface IProjectCardProps {
  name: string;
  queryTarget: number;
  totalLabels: number;
  totalQueries: number;
  onClick?: () => void;
  moreOptionsMenu: MenuProps;
}

const ProjectCard = ({
  name,
  queryTarget,
  totalLabels,
  totalQueries,
  onClick,
  moreOptionsMenu,
}: IProjectCardProps) => {
  const isQueryTargetReached = totalQueries > 0 && totalQueries >= queryTarget;

  return (
    <div className={styles.projectCard}>
      <div
        className={styles.clickLayer}
        onClick={onClick}
        onKeyDown={onClick}
        tabIndex={0}
        role="button"
      />
      <div className={styles.projectCard_header}>
        <Dropdown menu={moreOptionsMenu} placement="bottomRight" trigger={['click']}>
          <Button size="small" icon={<MoreOutlined />} onClick={(e) => e.preventDefault()} />
        </Dropdown>
      </div>
      <div className={styles.projectCard_body}>
        <h5>{name}</h5>
        <div className={styles.projectCard_body_labels}>
          <span className={styles.projectCard_body_labels_total}>{totalLabels}</span> {LABELS_LABEL}
        </div>
        <div className={styles.projectCard_body_queries}>
          <span
            className={`${styles.projectCard_body_queries_total} ${
              queryTarget && totalQueries && (isQueryTargetReached ? styles.success : styles.active)
            } 
            `}
          >
            {totalQueries}
          </span>{' '}
          {QUERIES_LABEL}
          {queryTarget > 0 && ` of ${queryTarget}`}
        </div>
        {queryTarget > 0 && (
          <Progress
            percent={(totalQueries / queryTarget) * 100}
            status={isQueryTargetReached ? 'success' : 'normal'}
            showInfo={isQueryTargetReached}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectCard;
