import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Tabs, Tag } from 'antd';
import { LEGACY_LABEL } from '@constants/common';
import { FilterProp, FiltersProp, FilterType, SelectedFilterItem } from '@constants/data-table';
import {
  PIPELINE_TEMPLATES_FILTERS_FIELD_KEYS,
  PIPELINE_TEMPLATES_FILTERS_TAGS_LABEL,
  PIPELINE_TEMPLATES_SORTING_OPTIONS,
  SEARCH_TEMPLATES_PLACEHOLDER,
  VERSION_TABS_LABELS,
} from '@constants/pipeline-templates';
import {
  getPipelineTemplatesFilterTags,
  resetSelectTemplatesFilters,
  selectTemplatesCategory,
  selectTemplatesFilters,
  selectTemplatesSortValue,
  setActiveVersionTabTemplatesLandingPage,
  setTemplatesSearchValue,
} from '@redux/actions/pipelineTemplatesActions';
import {
  activeVersionTabTemplatesLandingPageSelector,
  pipelineTemplatesFiltersValuesSelector,
  pipelineTemplatesSortValueSelector,
  selectedPipelineTemplateFiltersSelector,
} from '@redux/selectors/pipelineTemplatesSelectors';
import { DeepsetCloudVersion } from '@redux/types/types';
import AppliedFiltersList from '@components/appliedFilterList/AppliedFiltersList';
import DataHeaderActions from '@components/dataHeaderActions/DataHeaderActions';
import styles from './templatesActionsHeader.module.scss';

const TemplatesActionsHeader = () => {
  const dispatch = useDispatch();
  const activeVersionTabTemplatesLandingPage = useSelector(
    activeVersionTabTemplatesLandingPageSelector,
  );
  const pipelineTemplatesFiltersValues = useSelector(pipelineTemplatesFiltersValuesSelector);
  const selectedPipelineTemplateFilters = useSelector(selectedPipelineTemplateFiltersSelector);
  const pipelineTemplatesSortValue = useSelector(pipelineTemplatesSortValueSelector);

  useEffect(() => {
    dispatch(getPipelineTemplatesFilterTags());
  }, []);

  const getPipelineTemplatesFilters = (): FiltersProp<FilterType.MULTI_SELECT> => {
    const { tags } = pipelineTemplatesFiltersValues;

    const tagsFilter = {
      type: FilterType.MULTI_SELECT,
      key: PIPELINE_TEMPLATES_FILTERS_FIELD_KEYS.TAGS_ID,
      title: PIPELINE_TEMPLATES_FILTERS_TAGS_LABEL,
      options: tags,
    } as FilterProp<FilterType.MULTI_SELECT>;

    return [tagsFilter];
  };

  const onSearch = (searchValue: string) => {
    dispatch(selectTemplatesCategory(null));
    dispatch(setTemplatesSearchValue(searchValue));
  };

  const onFilterSelectChange = (filterKey: string, items: SelectedFilterItem[]) => {
    dispatch(selectTemplatesFilters(filterKey, items));
  };

  const onClearAllFilters = () => {
    dispatch(resetSelectTemplatesFilters);
  };

  const onSortSelectChange = (value: string) => {
    dispatch(selectTemplatesSortValue(value));
  };

  const onChangeDeepsetCloudVersionTab = (activeKey: DeepsetCloudVersion) => {
    dispatch(setActiveVersionTabTemplatesLandingPage(activeKey));
  };

  return (
    <div className={styles.container}>
      <Tabs
        className={styles.tabs}
        activeKey={activeVersionTabTemplatesLandingPage}
        onChange={(activeKey) => onChangeDeepsetCloudVersionTab(activeKey as DeepsetCloudVersion)}
        items={VERSION_TABS_LABELS.map((tab) => {
          return {
            label: (
              <span className={styles.tabs_title}>
                {tab.label}
                {tab.key === DeepsetCloudVersion.V1 && <Tag bordered={false}>{LEGACY_LABEL}</Tag>}
              </span>
            ),
            key: tab.key,
          };
        })}
      />
      <Divider className={styles.horizontalDivider} />

      <div className={styles.filtersBar}>
        <DataHeaderActions
          filters={getPipelineTemplatesFilters()}
          filterValues={selectedPipelineTemplateFilters}
          onFilterSelectChange={onFilterSelectChange}
          searchAvailable
          onSearch={onSearch}
          searchPlaceholder={SEARCH_TEMPLATES_PLACEHOLDER}
          onSortSelectChange={onSortSelectChange}
          sorting={{
            selectedValue: pipelineTemplatesSortValue,
            options: PIPELINE_TEMPLATES_SORTING_OPTIONS,
          }}
        />
      </div>
      <div className={styles.appliedFiltersBar}>
        <AppliedFiltersList
          filterValues={selectedPipelineTemplateFilters}
          onClearAllFilters={onClearAllFilters}
          onFilterSelectChange={onFilterSelectChange}
        />
      </div>
    </div>
  );
};

export default TemplatesActionsHeader;
